import React, { useState, useEffect } from "react";
import { auth, db } from "../../firebase";
import { TextField, Button, Typography, makeStyles } from "@material-ui/core";
import phone from "phone";
import translateError from "../../utils/translateError";
import PrefixSelect from "../PhonePrefixSelect";

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: theme.spacing(5),
  },
  buttonbox: {
    [theme.breakpoints.up("sm")]: {
      textAlign: "right",
    },
  },
  button: {
    marginTop: theme.spacing(5),
  },
  error: {
    marginTop: theme.spacing(2),
  },
}));

const RegistrationForm = () => {
  const [email, setEmail] = useState("");
  const [passwordOne, setPasswordOne] = useState("");
  const [passwordTwo, setPasswordTwo] = useState("");
  const [countryISOCode, setCountryISOCode] = useState("");
  const [phonePrefix, setPhonePrefix] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [formNotValid, setFormNotValid] = useState(true);
  const [error, setError] = useState(null);

  const classes = useStyles();

  useEffect(() => {
    const validateMobileFormat = () => {
      const result = phone(phoneNumber, {
        country: countryISOCode,
        validateMobilePrefix: false,
      });

      return result.isValid;
    };
    setFormNotValid(
      passwordOne !== passwordTwo ||
        passwordOne === "" ||
        email === "" ||
        !validateMobileFormat()
    );
  }, [
    passwordOne,
    passwordTwo,
    email,
    phoneNumber,
    phonePrefix,
    countryISOCode,
  ]);

  const onSubmit = (event) => {
    auth
      .doCreateUserWithEmailAndPassword(email, passwordOne)
      .then(async (authUser) => {
        await auth.doSendVerificationEmail();

        // Create a user object in db
        await db.doCreateUser(
          authUser.user.uid,
          email,
          countryISOCode,
          phonePrefix,
          phoneNumber
        );
      })
      .catch((error) => {
        const _error = translateError(error);
        setError(_error);
      });
    event.preventDefault();
  };

  const hanldePhoneChange = (event, child) => {
    setPhonePrefix(child.props.prefix);
    setCountryISOCode(child.props.code);
  };

  return (
    <form onSubmit={onSubmit}>
      <Typography variant="h5" className={classes.title}>
        Regístrate
      </Typography>

      <PrefixSelect
        onChangePrefix={hanldePhoneChange}
        onChangePhoneNumber={(event) => setPhoneNumber(event.target.value)}
        phonePrefix={phonePrefix}
        phoneNumber={phoneNumber}
        countryISOCode={countryISOCode}
      />

      <TextField
        id="email-input"
        variant="outlined"
        label="Email"
        onChange={(event) => setEmail(event.target.value)}
        margin="normal"
        fullWidth={true}
      />

      <TextField
        id="password-input"
        label="Contraseña"
        variant="outlined"
        value={passwordOne}
        type="password"
        onChange={(event) => setPasswordOne(event.target.value)}
        margin="normal"
        fullWidth={true}
      />

      <TextField
        id="password2-input"
        label="Confirmar contraseña"
        value={passwordTwo}
        variant="outlined"
        type="password"
        onChange={(event) => setPasswordTwo(event.target.value)}
        margin="normal"
        fullWidth={true}
      />

      {error && (
        <Typography className={classes.error} color="error" error>
          {error.message}
        </Typography>
      )}

      <Typography variant="h5" align="right">
        <Button
          color="primary"
          variant="contained"
          disabled={formNotValid}
          type="submit"
          className={classes.button}
        >
          Enviar
        </Button>
      </Typography>
    </form>
  );
};

export default RegistrationForm;
